var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{},[_c('div',{staticClass:"form-row"},[_c('sgv-input-text',{staticClass:"col-6",attrs:{"disabled":_vm.$auth.disabled(_vm.method),"label":"รหัส","validations":[
        {text: 'required!', value: _vm.v.formData.code.$dirty && !_vm.v.formData.code.required}
      ]},model:{value:(_vm.formData.code),callback:function ($$v) {_vm.$set(_vm.formData, "code", $$v)},expression:"formData.code"}}),_c('sgv-input-text',{staticClass:"col-6",attrs:{"disabled":_vm.$auth.disabled(_vm.method),"label":"ชื่อ","validations":[
        {text: 'required!', value: _vm.v.formData.name.$dirty && !_vm.v.formData.name.required}
      ]},model:{value:(_vm.formData.name),callback:function ($$v) {_vm.$set(_vm.formData, "name", $$v)},expression:"formData.name"}})],1),_c('div',[_c('div',{staticClass:"form-row"},[_c('sgv-input-text',{staticClass:"col-12",attrs:{"disabled":_vm.$auth.disabled(_vm.method),"label":"Email","validations":[
          {text: 'required!', value: _vm.v.formData.email.$dirty && !_vm.v.formData.email.required},
          {text: 'invalid email!', value: _vm.v.formData.email.$dirty && !_vm.v.formData.email.email}
        ]},model:{value:(_vm.formData.email),callback:function ($$v) {_vm.$set(_vm.formData, "email", $$v)},expression:"formData.email"}}),_c('sgv-input-text',{staticClass:"col-12",attrs:{"disabled":_vm.$auth.disabled(_vm.method),"label":"Token","validations":[
          {text: 'required!', value: _vm.v.formData.token.$dirty && !_vm.v.formData.token.required}
        ]},model:{value:(_vm.formData.token),callback:function ($$v) {_vm.$set(_vm.formData, "token", $$v)},expression:"formData.token"}})],1)]),_c('div',{staticClass:"form-row"},[_c('div',{staticClass:"form-group col-12"},[_c('sgv-input-check',{attrs:{"disabled":_vm.$auth.disabled(_vm.method),"label":"ใช้งาน","inline":""},model:{value:(_vm.formData.isActive),callback:function ($$v) {_vm.$set(_vm.formData, "isActive", $$v)},expression:"formData.isActive"}})],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }