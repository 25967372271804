var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{},[_c('div',{staticClass:"form-row"},[_c('sgv-input-text',{staticClass:"col-12 col-md-6",attrs:{"label":"From","validations":[
        {text: 'required!', value: _vm.$v.formData.from.$dirty && !_vm.$v.formData.from.required}
      ]},model:{value:(_vm.formData.from),callback:function ($$v) {_vm.$set(_vm.formData, "from", $$v)},expression:"formData.from"}}),_c('sgv-input-text',{staticClass:"col-12 col-md-6",attrs:{"label":"To","validations":[
        {text: 'required!', value: _vm.$v.formData.to.$dirty && !_vm.$v.formData.to.required}
      ]},model:{value:(_vm.formData.to),callback:function ($$v) {_vm.$set(_vm.formData, "to", $$v)},expression:"formData.to"}}),_c('sgv-input-text',{staticClass:"col-12",attrs:{"label":"Subject","validations":[
        {text: 'required!', value: _vm.$v.formData.subject.$dirty && !_vm.$v.formData.subject.required}
      ]},model:{value:(_vm.formData.subject),callback:function ($$v) {_vm.$set(_vm.formData, "subject", $$v)},expression:"formData.subject"}}),_c('sgv-input-textarea',{staticClass:"col-12",attrs:{"label":"Text","validations":[
        {text: 'required!', value: _vm.$v.formData.text.$dirty && !_vm.$v.formData.text.required}
      ]},model:{value:(_vm.formData.text),callback:function ($$v) {_vm.$set(_vm.formData, "text", $$v)},expression:"formData.text"}})],1),_c('button',{staticClass:"btn btn-warning",attrs:{"type":"button"},on:{"click":_vm.sendMail}},[_vm._v(" Send Mail ")])])}
var staticRenderFns = []

export { render, staticRenderFns }