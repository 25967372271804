<template>
  <div class="">
    <div class="form-row">
      <sgv-input-text
        class="col-12 col-md-6"
        label="From"
        v-model="formData.from"
        :validations="[
          {text: 'required!', value: $v.formData.from.$dirty && !$v.formData.from.required}
        ]">
      </sgv-input-text>

      <sgv-input-text
        class="col-12 col-md-6"
        label="To"
        v-model="formData.to"
        :validations="[
          {text: 'required!', value: $v.formData.to.$dirty && !$v.formData.to.required}
        ]">
      </sgv-input-text>

      <sgv-input-text
        class="col-12"
        label="Subject"
        v-model="formData.subject"
        :validations="[
          {text: 'required!', value: $v.formData.subject.$dirty && !$v.formData.subject.required}
        ]">
      </sgv-input-text>

      <sgv-input-textarea
        class="col-12"
        label="Text"
        v-model="formData.text"
        :validations="[
          {text: 'required!', value: $v.formData.text.$dirty && !$v.formData.text.required}
        ]">
      </sgv-input-textarea>
    </div>

    <button
      type="button"
      class="btn btn-warning"
      @click="sendMail">
      Send Mail
    </button>
  </div>
</template>

<script>
import { required } from 'vuelidate/lib/validators'
import { SEND_MAIL } from './graph'

export default {
  props: {
    deviceId: {
      type: Number,
      required: true
    },
    deviceType: {
      type: String,
      required: true
    },
    templateType: {
      type: String,
      required: true
    },
  },
  data () {
    return {
      formData: {
        from: '',
        to: '',
        subject: '',
        text: ''
      }
    }
  },
  validations: {
    formData: {
      from: { required },
      to: { required },
      subject: { required },
      text: { required },
    }
  },
  methods: {
    sendMail () {
      this.$v.formData.$touch()
      if (this.$v.formData.$invalid) return

      this.$apollo.mutate({
        mutation: SEND_MAIL(this.templateType),
        variables: {
          deviceType: this.deviceType,
          deviceId: this.deviceId,
          input: this.formData
        }
      }).then(() => {
        this.$v.formData.$reset()
        this.$toasted.global.success('success')
      }).catch(err => {
        this.$toasted.global.error(err)
      })
    },
  }
}
</script>

<style lang="css" scoped>
</style>
